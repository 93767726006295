<template>
  <el-form :model="query" ref="query" label-width="70px" class="search-form">
    <el-row>
      <el-form-item label="关键词" prop="keyword" style="width: 180px">
        <el-col>
          <el-input v-model="query.keyword" prefix-icon="Search"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="公司" label-width="80px">
        <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
          @change="departmentId = ''; companyChange();" check-strictly :render-after-expand="false"
          style="width: 220px;" />
      </el-form-item>

      <el-form-item label="部门" prop="departmentId" label-width="60px">
        <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
          @change="fetchs" :render-after-expand="false" style="width:120px" />
      </el-form-item>

      <el-form-item label="状态" prop="isEnabled">
        <el-select v-model="isEnabled" clearable placeholder="" @change="fetchs()" style="min-width: 80px;">
          <el-option key="1" label="启用" value="1"></el-option>
          <el-option key="0" label="禁用" value="0"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="" label-width="20px">
        <el-button type="primary" icon="search" @click="fetchs">
          搜索
        </el-button>
      </el-form-item>
    </el-row>
  </el-form>

  <basic-table ref="table" class="table" :query="query" :selects="checkedKeys" @handler="fetchs">
    <template v-slot:isEnabled="scope">
      <el-switch v-model="scope.row.isEnabled" active-color="#13ce66" inactive-color="#ff4949" active-text=""
        inactive-text="" :disabled="true">
      </el-switch>
    </template>
  </basic-table>
</template>
<script>
import BasicTable from "@/components/basic-table.vue";

export default {
  name: "UserRole",
  components: { BasicTable },
  props: {},
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "selection" },
          { type: "index", label: "序号" },
          { prop: "realName", label: "姓名", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "mobile", label: "手机号", sortable: true, searchable: true, width: "110", },
          { prop: "gender", label: "性别", width: "60", searchable: true, },
          { type: "template", prop: "isEnabled", label: "状态", sortable: true, width: "80", },
          { prop: "sortCode", label: "排序号", sortable: true, width: "85", },
          { prop: "loginDate", label: "最后登录时间", width: "140" },
          { prop: "createDate", label: "创建时间", width: "180" },
        ],
        filters: []
      },
      checkedKeys: [],

      isEnabled: null,
      companyId: '',
      companys: [],
      departmentId: '',
      departments: [],
    };
  },
  methods: {
    fetchs() {
      //console.log('fetchs', this.query);
      this.query.filters = [];
      //this.query.filters.push({ name: "isEnabled", value: '1', operate: 0 });

      if (this.companyId && this.companyId.length > 0) {
        this.query.filters.push({ name: "companyId", value: this.companyId, operate: 6, });
      }
      if (this.departmentId && this.departmentId.length > 0) {
        this.query.filters.push({ name: "departmentId", value: this.departmentId, operate: 6, });
      }
      if (this.isEnabled) {
        this.query.filters.push({ name: "isEnabled", value: this.isEnabled, operate: 0, });
      }

      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };//selects: this.checkedKeys,
        //console.log('this.query', this.query);
      });

    },

    getSelectIds() {
      console.log('getSelectIds', this.$refs.table.selectIds);
      return this.$refs.table.selectIds;
    },
    setCheckedKeys(keys) {
      this.checkedKeys = keys;
      //this.query.selects = keys;
      console.log('setCheckedKeys', keys.length);
    },

    // currHandler: function (row) {
    //   console.log('select1111111 currentHandler', row);
    // },

    companyChange() {
      this.departmentId = '';
      var that = this;
      if (!this.companyId) {
        that.fetchs();
        return;
      }

      this.$kaung.departments(this.companyId).then((res) => {
        that.departments = res;
        that.fetchs();
      });
    },
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init("BaseUser");

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.companyChange();
    });
  },
  watch: {
    query: function (newValue, oldValue) {
      // console.log('user-select',newValue);
      // let that = this;
      // if (newValue && newValue.selects)
      //   setTimeout(() => {
      //     newValue.selects.forEach(item => {
      //       that.$refs.multipleTable.toggleRowSelection(item);
      //     })
      //   });
    }
  }
};
</script>