<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()">新增</el-button>
      <el-button type="danger" icon="delete" @click="del()">删除</el-button>
      <el-button type="primary" icon="plus" @click="openUserSelect()">角色用户</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:category="scope">
        <span v-if="scope.row.category == 'global'">通用</span>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small" type="primary">[编辑]</el-button>
        <el-button @click="openRolePower(scope.row)" link size="small" type="primary">[权限]</el-button>
      </template>
      <!-- <template v-slot:isEnabled="scope">
      <el-switch v-model="scope.row.isEnabled" active-color="#13ce66" inactive-color="#ff4949" active-text="" inactive-text="" :disabled="true">
      </el-switch>
    </template> -->
      <template v-slot:birthday="scope">
        <i class="el-icon-time" v-show="scope.row.birthday"></i>

        <span style="margin-left: 10px">{{ (scope.row.birthday || "").split(" ")[0] }}</span>
      </template>
    </static-table>
  </el-card>

  <static-dialog ref="dialog" class="dialog" title="角色维护" :opened="opened" :ok="submit">
    <el-form :model="forms" :rules="formRules" ref="forms" :label-width="formLabelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="角色类别" prop="category">
            <el-select v-model="forms.category" placeholder="请选择类别" required>
              <el-option label="通用" value="global"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="forms.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="角色编码" prop="code">
            <el-input v-model="forms.code" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="排序号" prop="sortCode">
            <el-input-number v-model="forms.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="备注">
            <el-input type="textarea" placeholder="请输入备注内容" v-model="forms.remark" autocomplete="off" show-word-limit
              :rows="2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>

  <static-dialog ref="userDialog" class="dialog" title="角色用户" :ok="userSubmit" width="950">
    <user-select ref="userSelect"></user-select>
  </static-dialog>

  <static-dialog ref="powerDialog" class="dialog" title="权限维护" :ok="powerSubmit">
    <!-- <el-select v-model="menuCategory" placeholder="请选择类别" required @change="fetctMenuData();">
      <el-option v-for="value in menuCategorys" :label="value" :value="value"></el-option>
    </el-select> -->
    <el-scrollbar style="height: 45vh; width:30%;">
      <el-tree ref="powerTree" :data="treeData" show-checkbox node-key="id" default-expand-all
        @check-change="checkEvent" :props="defaultProps">
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span>
              <component :is="data.icon" style="width: 15px; height:15px;" />
              {{ node.label }}
            </span>
          </span>
        </template>
      </el-tree>
    </el-scrollbar>

  </static-dialog>
</template>
<script>
import { fetchMenus } from "@/api/basic";
import { fetchRoles, delRoles, submitRole, submitRelationByGlobalId, submitRelationByObjectId, fetchRelationByGlobalId, fetchRelationByObjectId } from "@/api/sys";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import UserSelect from "@/views/sys/user/select.vue";

export default {
  name: "Role",
  components: { StaticDialog, StaticTable, UserSelect },
  data() {
    return {
      // menuCategory: '后台',
      // menuCategorys: ['后台', '侧边栏'],

      currRow: null,
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "template", prop: "category", label: "类别", width: "120", sortable: true, searchable: true, },
          { prop: "name", label: "角色名", width: "150", sortable: true, searchable: true, },
          { prop: "code", label: "角色编号", sortable: true, searchable: true, width: "110", },
          { prop: "sortCode", label: "排序号", sortable: true, width: "100", },
          { prop: "remark", label: "备注", sortable: true, },
          { prop: "createDate", label: "创建时间", width: "150" },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "sortCode", //默认的排序字段
        sord: "descending",
      },

      forms: {},
      formLabelWidth: "90px",
      formRules: {
        category: [{ required: true, message: "", trigger: "blur" }],
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入角色编码", trigger: "blur" },
        ],
        sortCode: [
          { required: true, message: "请输入角色排序号码", trigger: "blur" },
        ],
      },

      powerDialogVisible: false,
      powerId: '',
      treeData: [],
      checkedKeys: [],
      //   {
      //     id: 1,
      //     label: "一级 1",
      //     children: [
      //       {
      //         id: 4,
      //         label: "二级 1-1",
      //         children: [
      //           {
      //             id: 9,
      //             label: "三级 1-1-1",
      //           },
      //           {
      //             id: 10,
      //             label: "三级 1-1-2",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     label: "一级 2",
      //     children: [
      //       {
      //         id: 5,
      //         label: "二级 2-1",
      //       },
      //       {
      //         id: 6,
      //         label: "二级 2-2",
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     label: "一级 3",
      //     children: [
      //       {
      //         id: 7,
      //         label: "二级 3-1",
      //       },
      //       {
      //         id: 8,
      //         label: "二级 3-2",
      //       },
      //     ],
      //   },
      //   {
      //     id: 9,
      //     label: "一级 4",
      //     children: [
      //       {
      //         id: 10,
      //         label: "二级 4-1",
      //       },
      //       {
      //         id: 11,
      //         label: "二级 4-2",
      //       },
      //       {
      //         id: 12,
      //         label: "二级 4-2",
      //       },
      //       {
      //         id: 13,
      //         label: "二级 4-2",
      //       },
      //     ],
      //   },
      // ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    edit(row) {
      this.forms = { ...row }; //JSON.parse(JSON.stringify(row));
      this.$refs.dialog.toggle();
    },
    add() {
      this.forms = { sortCode: 1 };
      this.$refs.dialog.toggle();
    },
    opened() {
      this.forms.sortCode = this.forms.sortCode || 1;
    },
    close() {
      this.$refs.forms.resetFields();
    },
    del() {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          delRoles(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    fetchs() {
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      fetchRoles(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    openUserSelect() {
      if (!this.currRow) {
        this.$message({
          type: "error",
          message: "请先选择角色",
        });
        return;
      }

      fetchRelationByObjectId('user-role', this.currRow.id).then((res) => {
        this.checkedKeys = res.data;
        //console.log('user-role', this.checkedKeys);
        this.$refs.userSelect.setCheckedKeys(res.data);
      });

      this.$refs.userDialog.toggle();
    },
    userSubmit() {
      let that = this;
      let userIds = this.$refs.userSelect.getSelectIds();
      if (userIds.length == 0) {
        that.$message({ type: "info", message: "请勾选对应用户数据", });
        return;
      }
      //let roleArr = [];
      //userSelector.forEach(q => roleArr.push({ GlobalID: q }));

      //console.log('submit role users', userIds);
      submitRelationByObjectId(userIds, 'user-role', that.currRow.id).then((res) => {
        if (res.code === 200) {
          that.$message({
            type: "success",
            message: res.content,
          });
          setTimeout(function () {
            that.$refs.userDialog.toggle();  //关闭弹出框
          }, 500);
        }
      });
    },
    submit() {
      let that = this;
      this.$refs.forms.validate((valid) => {
        if (!valid) return;
        submitRole(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });
            this.$refs.dialog.toggle();
            this.fetchs();
          }
        });
      });
    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },

    fetctMenuData() {
      var that = this;
      fetchMenus().then((res) => {
        that.treeData = res;
        //加载权限数据
        that.fetchPowerData(that.powerId);
      });
    },
    checkEvent(data, status) {
    },
    powerSubmit() {
      this.$nextTick(() => {
        //勾选的节点
        let checkArray = this.$refs.powerTree.getCheckedNodes();
        let powerArr = [];
        //checkArray.forEach(q => powerArr.push({ ObjectID: q.id }));
        checkArray.forEach(q => powerArr.push(q.id));
        //console.log(powerArr);
        submitRelationByGlobalId(powerArr, 'role-menu', this.powerId).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: res.content,
            });
          }
        });
      });

      // 为了把父节点也找到并进行保存
      // //勾选的节点对应主节点所有的节点
      // let checkNodes = JSON.parse(JSON.stringify(checkArray));
      // //勾选过的主节点
      // this.$refs.powerTree.getHalfCheckedNodes().forEach(element => {
      //   this.getCheckedArray(element, checkNodes);
      // });
      // checkNodes = checkNodes.filter(function (x) {
      //   return checkArray.filter(y => x.children.length > 0 || x.id == y.id).length > 0;
      // });
    },
    getCheckedArray(node, checkNodes) {
      if (node && node.children && node.children.length > 0) {
        node.children.forEach(element => {
          return this.getCheckedArray(element, checkNodes);
        });
      }
      if (node && checkNodes.filter(x => x.id == node.id).length == 0)
        checkNodes.push(node);
    },
    fetchPowerData(globalId) {
      var that = this;
      that.$nextTick(() => {
        fetchRelationByGlobalId('role-menu', globalId).then((res) => {
          that.checkedKeys = [];
          res.data.forEach(key => {
            let node = that.$refs.powerTree.getNode(key);
            if (node && node.childNodes.length == 0) {
              that.checkedKeys.push(key); //可以过滤父级节点key避免将子菜单全选了
            }
          });

          that.$refs.powerTree.setCheckedKeys(that.checkedKeys);
        });
      });

    },
    openRolePower(row) {
      this.powerId = row.id;
      this.fetctMenuData();
      this.$refs.powerDialog.toggle();
    },
  },
  mounted() {
    this.fetchs();
  },
};
</script>